import Head from 'next/head';

import styles from './layout.module.scss';

export default function Layout({children, title}) {
  return (
    <>
      <Head>
        <meta key="robots" name="robots" content="noindex, nofollow" />
        <meta key="googlebot" name="googlebot" content="noindex, nofollow" />
        <title>{title}</title>
      </Head>
      <div className={styles.container}>{children}</div>
    </>
  );
}
