import {useRouter} from 'next/router';
import UseCSV from '@usecsv/react';

import Layout from '../../components/layout';

const Index = () => {
  const router = useRouter();
  const {token} = router.query;

  const renderButton = (openModal) => {
    return (
      <button
        onClick={openModal}
        style={{
          width: '250px',
          height: '90px',
          color: '#fff',
          backgroundColor: '#027ad6',
          boxShadow: '0 32px 64px rgba(0,0,0,0.07)',
          font: '700 24px sans-serif',
          textAlign: 'center',
          border: 'none',
          borderRadius: '3px',
          cursor: 'pointer',
        }}
      >
        Import Orders
      </button>
    );
  };

  return (
    <Layout title="Order Import">
      <h1>Devea Subscribe</h1>
      <UseCSV
        importerKey={token}
        render={(openModal) => renderButton(openModal)}
        onData={async (data) => {
          const {rows} = data;

          let errorRows = [];

          for (const row of rows) {
            const response = await fetch('/api/order', {
              body: JSON.stringify(row),
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
              method: 'POST',
            }).then(function (res) {
              return res.json();
            });

            if ('error' in response) {
              errorRows.push({row, error: response.error});
            }
          }

          return Promise.resolve({
            errors: errorRows,
          });
        }}
      />
    </Layout>
  );
};

export default Index;
